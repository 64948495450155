import React from 'react'
import { Link } from 'gatsby'

const Contact = (props) => (
  <section id="contact">
    <div className="inner">
      <section>
        <ul className="actions">
          <li><Link to="/contact" className="button">Contact Us</Link></li>
        </ul>
      </section>
      <section className="split">
        <section>
          <div className="contact-method">
            <p>We look forward to hearing from you.</p>
          </div>
        </section>
      </section>
    </div>
  </section>
)

export default Contact
